<template>
  <div class="py-5">
    <!-- Status picker and add user -->
    <div class="d-flex justify-content-between flex-wrap">
      <div class="col-lg-6 m-0 p-0 text-left"> 
        <button v-bind:class="{'font-weight-bold': status == ACTIVE_STATUS}" class="status-button" @click="status = ACTIVE_STATUS">Ativos</button> <span  v-bind:class="{'font-weight-bold': status == ACTIVE_STATUS}">({{activeCount}})</span> | <button v-bind:class="{'font-weight-bold': status == DISABLED_STATUS}" class="status-button" href="#" @click="status = DISABLED_STATUS">Arquivo</button> <span v-bind:class="{'font-weight-bold': status == DISABLED_STATUS}">({{disabledCount}})</span>
      </div>
      <button id="add-button" type="button" class="btn color-button text-light d-flex align-items-center" @click="addUser">
        <span class="d-none d-lg-block">Adicionar Utilizador</span>
        <span class="d-block d-lg-none font-weight-bold">+</span>
      </button>
    </div>
    <!-- Search bar -->
    <div class="d-flex align-items-center flex-wrap my-4">
      <div class="col-12 col-lg-6">
        <SearchBar @search-clicked="searchClicked"></SearchBar>
      </div>
    </div>
    <!-- List: this will have different UI on mobile -->
    <CTableWrapper ref="table" hover striped :caption="caption" :items="items" :actions="actions" :pages="pages" :fields="fields" clickable-rows @row-clicked="rowClicked" @action-clicked="actionClicked" @update-requested="updateItemsRequested" :sorter='{ external: false, resetable: false }' :loading="loading" paginationSubtitle="Utilizadores por Página">
    </CTableWrapper>
  </div>
</template>
<script>
import SearchBar from '@/controls/SearchBar.vue'
import { mapState, mapActions } from 'vuex'
import CTableWrapper from '@/controls/PaginatedTable.vue'
import router from '@/router';
import InputGetUsers from "@/classes/InputGetUsers";
import EventBus from '@/events/bus.js';

const DISABLED_STATUS = false;
const ACTIVE_STATUS = true;

export default {
  components: { CTableWrapper, SearchBar },
  data () {
    return {
      status: ACTIVE_STATUS,
      caption: "Utilizadores",
      currentPage: 1,
      loading: false,
      itemsPerPage: localStorage.itemsPerPage ?? 10,
      fields: [
        { key: 'name', label: ('Nome'), _classes: 'font-weight-bold', sortable: false },
        { key: 'email', label: ('Email'), _classes: 'font-weight-bold', sortable: false },
      ],      
      actions: [
        { key: 'view', image: 'iconRightArrowWhite.png', imageBig: 'iconRightArrow.png' },
        { key: 'disable', image: 'iconFolderWhite.png', imageBig: 'iconFolder.png' }
      ],
      // Provide access to consts on HTML
      DISABLED_STATUS: DISABLED_STATUS,
      ACTIVE_STATUS: ACTIVE_STATUS
    }
  },
  computed: {
    ...mapState('account', ['listOutput']),
    ...mapState('account', ['processTask']),
    usersInfo: function() {
      return this.listOutput && this.listOutput.data ? this.listOutput.data : null;
    },
    items: function()
    {
      var result = [];

     if(this.usersInfo && this.usersInfo.users)
      {
        result = this.usersInfo.users;
      }
      return result;
    },
    activeCount: function()
    {
      return this.usersInfo ? this.usersInfo.active : 0;
    },
    disabledCount: function()
    {
      return this.usersInfo ? this.usersInfo.inactive : 0
    },
    pages: function()
    {
      return this.listOutput ? this.listOutput.totalPages : 1;
    },
  },
  mounted: function()
  {
    // Active have default value of true
    this.active = true;
    this.updateItemsRequested(1, this.itemsPerPage);
  },
  methods: {
    ...mapActions('account', ['listUsers']),
    ...mapActions('account', ['updateStatus']),
    rowClicked (item) {
      this.$router.push({name: "EditUser", params: { user: item } });
    },
    actionClicked(item, action){
      switch(action.key)
      {
        case 'view':
        {
          this.$router.push({name: "EditUser", params: { user: item } });
          break;
        }
        case 'disable':
        {
          EventBus.$emit("warning-alert-requested", { message: `Tem a certeza que pretende arquivar o utilizador '${item.name}'?`, primaryCallback: () =>
          {
            this.loading = true;
            this.updateStatus(item.email);
          }, secondaryButton: "Cancelar"});
          break;
        }
        case 'enable':
        {
          EventBus.$emit("warning-alert-requested", { message: `Tem a certeza que pretende ativar o utilizador '${item.name}'?`, primaryCallback: () =>
          {
            this.loading = true;
            this.updateStatus(item.email);
          }, secondaryButton: "Cancelar"});
          break;
        }
        default:
        {
          throw "Ação não suportada!"; 
        }
      }
    },
    updateItemsRequested(currentPage, itemsPerPage)
    {
      this.loading = true;
      
      //Make request
      this.currentPage = currentPage;
      this.itemsPerPage = itemsPerPage;
      const propertyName = "id";
      const orderBy = 0;

      var input = new InputGetUsers(currentPage, itemsPerPage, this.searchText, orderBy, propertyName, this.active);
      this.listUsers(input);       
    },
    addUser () 
    {
      router.push({ path: '/utilizadores/adicionar' });
    },
    searchClicked(searchText)
    {
      this.searchText = searchText;
      this.$refs.table.reset();
      this.updateItemsRequested(1, this.itemsPerPage);
    }, 
  },
  watch:
  {
    status: function (val) {
      // Update actions
      switch(val) {
        
        case ACTIVE_STATUS:
          {
            this.active = true;
            this.actions = [];        
            this.actions.push({ key: 'view', image: 'iconRightArrowWhite.png', imageBig: 'iconRightArrow.png' });
            this.actions.push({ key: 'disable', image: 'iconFolderWhite.png', imageBig: 'iconFolder.png'});
            
            break;
          }
        case DISABLED_STATUS:
          {
            this.active = false;
            this.actions = [];
            this.actions.push({ key: 'view', image: 'iconRightArrowWhite.png', imageBig: 'iconRightArrow.png' });
            this.actions.push({ key: 'enable', image: 'iconUnarchiveWhite.png', imageBig: 'iconUnarchive.png' });
            break;
          }
        default:
          {
            throw "The provided status is not supported!"; 
        }
      }

      // Update items
      this.$refs.table.reset();
      this.updateItemsRequested(1, this.itemsPerPage);
    },
    listOutput: function()
    {
      this.loading = false;
    },
    processTask: function(val)
    {
      if(val !== null)
      {
        this.loading = false;
        this.updateItemsRequested(this.currentPage, this.itemsPerPage);
      }
    }
  }
}
</script>

 
<style>
th
{
  border-top: none !important;
  color: white;
  background-color: rgb(143, 148, 251) !important; 
}

td
{
  color: rgb(143, 148, 251);
  font-weight: bold;
}

tr
{
  cursor: pointer;
}

.status-button
{
  cursor: pointer;
  border: none;
  padding: 0;
  text-decoration: none;
  color: rgb(143, 148, 251);
  font-size: 25px;
}

.color-button{
  cursor: pointer;
  background-color: rgb(78, 84, 200);
  border-radius: 15px;
}

.dropdown-container
{
  gap: 8px;
}

#add-button:hover span
{
  color: whitesmoke;
}

@media only screen and (max-width: 991px) {
  #add-button
  {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    position: relative;
  }
  #add-button > span
  {
    font-size: 28px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
  }
}

</style> 